
@import "~bootstrap/dist/css/bootstrap.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "../node_modules/ngx-toastr/toastr.css";

 /* ***********************common classes*********************** */
.container{
    /* min-height: 42.5vw; */
    }
    
    i.fa-info-circle {
        margin-left: 3px;
    }
 
.heading{
text-align: center !important;
font: normal normal bold 1.589vw Product Sans !important;
letter-spacing: 0px !important;
color: #3155A6 !important;
opacity: 1 !important;
}
.ng-select {
    box-sizing: border-box;
    margin: 0px !important;
}
.ng-select-set-height{
    height: 10px !important;
}
.ng-select-container {
    height: 2.1vw !important;
    font-size: 0.9vw !important;
    padding-top: 0.125VW !important;
/* // new css */
border: 1px solid #A7A7A7 !important;
border-radius: 0.39vw;
opacity: 1;
 }
 .Change .ng-select-container .ng-value-container .ng-placeholder {
    color: black !important;
}
::placeholder {
    color: rgb(187, 180, 180) !important;
    opacity: 1; /* Firefox */
  }
  
  ::-ms-input-placeholder { /* Edge 12-18 */
    color: gb(187, 180, 180) !important;
  }

 /* .ng-select.ng-select-single .ng-select-container {
    height: 21px !important;
} */
.page-header{
    text-align: left;
    font: normal normal bold 1.4157vw Product Sans !important;
    letter-spacing: 0px;
    color: #6B6B6B;
    opacity: 1;
}
.updateBtn{  
    height: 2.0965625vw;
    width: 3.5vw;
    background: #3155A6 0% 0% no-repeat padding-box;
    border-radius: 0.39vw;
    opacity: 1;
    text-align: center;
    font: normal normal normal 1.04vw/1.4vw Product Sans !important;
    letter-spacing: 0px;
    color: #FFFFFF;
    padding: 0px 6px 0px 5px;
}
.backbtn,.nextbtn{
  
    height: 1.865625vw;
    background: #3155A6 0% 0% no-repeat padding-box;
    border-radius: 0.39vw;
    opacity: 1;
    text-align: center;
font: normal normal normal 1.04vw/1.40vw Product Sans !important;
letter-spacing: 0px;
color: #FFFFFF;
padding: 0px 1.32vw 0px 1.48vw;
}
.nextbtn:focus,.backbtn:focus,.clearBtn:focus,.addbtn:focus{
    border-color: unset;
    box-shadow: unset;
    background-color: #3155A6;
    
}
.nextbtn:hover,.backbtn:hover,.clearBtn:hover,.addbtn:hover{
    border-color: unset;
    box-shadow: unset;
    background-color: #3155A6;
    
}
.finishbtn:hover{
    border-color: unset;
    box-shadow: unset;
    background-color: rgba(53, 179, 42, 0.815);

}
.finishbtn{

    width: 10%;
    height: 1.865625vw;
    background: rgba(53, 179, 42, 0.815) 0% 0% no-repeat padding-box;
    border-radius: 0.39vw;
    text-align: center;
    font: normal normal normal 1.04vw/1.40vw Product Sans !important;
     letter-spacing: 0px;
     color: #FFFFFF !important;
     opacity: 1;
     padding: 0px 1.32vw 0px 1.48vw;
}
label{
    text-align: left;
font: normal normal normal 1.0vw  Product Sans !important;
letter-spacing: 0px;
color: #6B6B6B;
opacity: 1;
margin-bottom: 0.4vw;
}
.fas{
    color: #3155A6;
}
input.form-control,
select.form-select{
    height: 2.10vw !important;
    font-size: 0.9vw !important;
    padding-top: 0.125VW !important;
/* // new css */
border: 1px solid #A7A7A7;
border-radius: 0.239vw;
opacity: 1;
/* color: gray; */
}
select{
    /* // font-size: 1.015625vw !important; */
border: 1px solid #A7A7A7;
border-radius: 0.26vw;
opacity: 1;
}

/* // bootstrap border none css */
.form-control:focus,.form-select:focus{
    border-color:#A7A7A7;
    outline:0;
    -webkit-box-shadow:none;
    box-shadow:none;
}

.fa-edit {
    cursor: pointer;
  }
.addbtn{
    height: 1.83vw;
    font-size: 1.015625vw;
    width: 6vw;
    background-color:#3155A6;
    padding: 0;
    border-radius: 0.38vw;
}
.clearBtn{
    height: 1.83vw;
    font-size: 1.015625vw;
    width: 10%;
    background-color:#3155A6;
    padding: 0;
    border-radius: 0.38vw;
}
.dlt-icon{
    color: red;
    font-size: 1.0vw;
    cursor: pointer;
}

/* // veiw page css */
.txt1
{
    font-size:  1.48vw;
    font-weight: 500;
}
.fa-edit
{
    font-size: 1.09vw;
    color:#3155A6;
    cursor: pointer;
}

.fa-file-pdf{
    font-size: 1.09vw;
    color:#3155A6;
    cursor: pointer;
}

.fa-file{
    font-size: 1.09vw;
    color:#3155A6;
    cursor: pointer;
}

.txt2
{
    font-size: 1.09vw;
}
.nextBtn
{
    background-color: green;
}
.btn-primary:disabled {
    color: #fff;
    background-color: #44619e;
    border-color: #44619e;
}

.mat-typography p {
    margin: 0 0 0px !important;
}

/* // end */
                                                                                                                                                                                                              
/* .mat-drawer-inner-container {
    width: 100%;
    height: 100%;
    overflow: hidden !important;
  } */

.btn-primary{
    color: #fff;
    /* // background-color: #0d6efd; */
    border-color: white;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* .mat-tab-label .mat-tab-label-content {
    font-size: 1.2779vw !important;
    color: black;
} */


.ng-select .ng-select-container {
    min-height:  0.634vw !important;
}
/* // .mat-ink-bar{
//     left: 27px !important;
//     width: 74px !important;
//     height: 5px !important;
// }
// .custom-tb-scroll{
//     height:28.64vw;
//     overflow-y:scroll;
// }
// ::-webkit-scrollbar {
//     width: 0px !important;
//     background: transparent !important; 
// }
// .mat-tab-body-content {
//     height:28.64vw;
//     overflow: scroll;
// } */

.mat-ripple-element{
    width: auto !important;
}
html, body { height: 100%; }
body { 
    margin: 0;
     font-family: Roboto, "Helvetica Neue", sans-serif; 
    }

    .ng-select .ng-select-container.newLookup {
        cursor: default;
        display: flex;
        outline: none;
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 22px !important;
    }


      /* multiselect dropdown */
.container .multiselect-dropdown .dropdown-btn {
    display: inline-block!important;
    width: 100%!important;
    padding: 6px 12px!important;
    margin-bottom: 0!important;
    font-size: 12px !important;
    font-weight: 400!important;
    line-height: 1.1 !important;
    border: 1px solid #adadad !important;
    text-align: left!important;
    vertical-align: middle!important;
    cursor: pointer!important;
    background-image: none!important;
    border-radius: 4px!important;
}
.dropdown-multiselect__caret{
    top: -22% !important;
}
.selected-item {
    border: 1px solid #A7A7A7 !important;
    margin-right: 4px;
    background: #fff !important;
    padding: 0 0px !important;
    color: #6B6B6B !important;
    border-radius: 2px;
    float: left;
    max-width: 100px;
}

.mat-list-base .mat-list-item, .mat-list-base .mat-list-option {
    display: block;
    height: 3.7vw !important;
    -webkit-tap-highlight-color: transparent;
    width: 100%;
    padding: 0;
}





.angular-editor-toolbar {
    font: 100 14px/15px Roboto,Arial,sans-serif;
    background-color: transparent !important;
    font-size: .8rem;
    padding: 0.2rem;
    border: none !important;   
    border: 1px solid   #A7A7A7!important;
    /* border-radius: 3px; */
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    
}
.angular-editor-toolbar .angular-editor-toolbar-set .angular-editor-button {
    background-color: transparent !important;
    vertical-align: middle !important;
    border: none !important;
    padding: 0.4rem !important;
    min-width: 2rem !important;
    float: left !important;
    
    /* color: #666666; */
}

.angular-editor .angular-editor-wrapper .angular-editor-textarea {
    min-height: 5rem;
    padding: 0.5rem 0.8rem 1rem;
    border: 1px solid   #A7A7A7!important;
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;


}
.angular-editor .angular-editor-wrapper .angular-editor-textarea:focus-visible{
    /* min-height: 5rem;
    padding: 0.5rem 0.8rem 1rem; */
    border: none !important;
    box-shadow: none !important;
    /* border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important; */


}
.angular-editor-textarea {
    min-height: 150px;
    overflow: auto;
    margin-top: 0px !important;
    resize: none !important;
    height: 50px !important;
    border: 1px solid   #A7A7A7!important;

} 
.form-check-input{
    font-size: 0.9416666666666667vw;
    border-radius: 0.2vw;
}